<template>
    <div id="prototyp" class="component-content">
        
        <video class="videoblock" controls>
            <source src="../assets/videos/prototyp-reaper.mp4" type="video/mp4">
            <source src="movie.ogg" type="video/ogg">
            Your browser does not support the video tag.
        </video> 

        <img class="imgF" src="../assets/proto-full.jpg"/> 

        <video class="videoblock" controls>
            <source src="../assets/videos/Touchscreen-Test-2-20.mp4" type="video/mp4">
            <source src="movie.ogg" type="video/ogg">
            Your browser does not support the video tag.
        </video> 

        <video class="videoblock" controls>
            <source src="../assets/videos/protofull.mp4" type="video/mp4">
            <source src="movie.ogg" type="video/ogg">
            Your browser does not support the video tag.
        </video> 
        
        <!-- <img alt="Vue logo" src="../assets/proto-touch.png"> -->
    </div>    
</template>

<script>
export default {
    name: 'Prototyp',
    props: {}
}
</script>

<style>
#prototyp {
    /* background-color: black; */
    /* width: inherit;
    height: inherit; */
    /* align-content: center;
    padding-top: 2rem;
    margin-bottom: 2rem; */
}
#proto-touch {
    transform: rotate(90deg);
}
/* #posV {
    transform: rotate(270deg);
} */
</style>
