<template>
    <div id="lesson" class="component-content">
        <div class="textblock">
            <h2 class="h2-orange"> Latenz: Mein größter Feind? Oder eine falsch verstandene Chance?</h2>
            <p>
                Schon zu Beginn des Projektes schwebte ein Damoklesschwert über der ganzen Unternehmung. Dieses trug den Namen Latenz. Die erste Recherche bestätigte relativ schnell, dass eine Live Performance online mit der Bandbreite und Zuverlässigkeit der Internetverbindung steht und fällt.
                Diese Problematik hat mich einige Zeit beschäftigt. Meine Bemühungen haben sich vor allem auf technischer Ebene bewegt in der Hoffnung die Latenz zuverlässig verringern zu können.
                Als ich mich jedoch mit der Charakterbestimmung für timbre befasst habe und den Magier als Archetyp wählte, blieb mir sein Umgang mit dem Drachen, also dem größten Hindernis im Kopf. So bezog sich Carol S. Pearson auf die Möglichkeit das Problem nicht herkömmlich zu lösen, sondern den Drachen “auszutricksen”. 
                Dieser Ansatz brachte mich auf die Idee das Konzept von der Latenz zu emanzipieren. Durch ein festes Metrum gebunden und um einen Takt verzögert wird der Audiostream einer musizierenden Person in die für alle Bandmitglieder hörbare Gesamtkomposition integriert. 
                Dadurch entsteht der Eindruck die Latenz sei gering. Dies führte natürlich zu einer Einschränkung bei der Nutzung des Tempo als kompositorisches stilmittel und gleichzeitiger Performance von verschiedenen Bandmitgliedern. Jedoch wird der Zugewinn nämlich die Möglichkeit online zu musizieren ohne den Flow zu verlieren von mir als größer eingeschätzt.
            </p>    
        </div>
        <div class="textblock">
            <h2 class="h2-orange"> Für jede Aufgabe gibt es eine angemessene Tageszeit</h2>
            <p>
                Morgens bis Mittags habe ich mich eher mit Routinearbeit beschäftigt oder Aufgaben des Haushalts erledigt. Hierzu muss ich auch sagen, dass ich absolut kein Morgenmensch bin und dies aktiv in mein Sprint Planung beachtet wurde. Hingegen wurden kreative Aufgaben vorzugsweise in der Nacht oder den frühen Morgenstunden absolviert.
            </p>    
        </div>
        <div class="textblock">
            <h2 class="h2-orange"> Konsequentes Projektmanagement ist die Basis von allem</h2>
            <p>    
                Bereits vor dem Projekt habe ich mir Gedanken gemacht, welche Projektmanagementmethode ich verwenden möchte und wie ich sie am besten einsetze. 
                Ein selbst gestecktes Ziel war hierbei die realistische Einschätzung der Menge an Tickets für den kommenden Milestone und damit verbunden eine retrospektivische des vergangen Milestones als Inspiration für die folgenden Milestones.
            </p>    
        </div>
        <div class="textblock">
            <h2 class="h2-orange"> Zu viel, zu ambitioniert?</h2>
            <p>
                Hoffentlich wird es nicht mein persönliches Dune von Alejandro Jodorowsky. An alles gedacht aber nichts gemacht. Dies waren meine Gedanken bevor ich mit timbre begann. 
                Im Verlaufe des Projektes gab es einige Stellen an denen eine Fokussierung auf das wesentliche notwendig wurde.
                Gerade beim Übergang von Konzept zu Umsetzung (Milestone 5-6) war eine zweiwöchige neue Evaluation von Prioritäten pro Sprint unverzichtbar.
            </p>    
        </div>
        <div class="textblock">
            <h2 class="h2-orange"> Schreiben, lesen … Legasthenie!?</h2>
            <p>
                Umso weiter ich mit dem Projekt timbre fortfahre, desto mehr stelle ich fest, dass das entwickeln von Mock-Ups, Prototypen in Technik und Design aber auch Geschäftsstrategien mir besonderen Spaß bereitet. 
                Der Recherche und Verschriftlichung von Ergebnissen als notwendige Routinearbeit, konnte ich wenig abgewinnen. 
                Aus diesem Grund habe ich versucht für jeden Arbeitstag Aufgaben aus dem Pool der angenehmen und unangenehmen Aufgaben gleichberechtigt zu bearbeiten.
                Dabei versuchte ich mit der unangenehmen Arbeit zu beginnen. Stellte ich fest das nach 20-30 min kein Fortschritt erreicht wurde zog ich eine der angenehmeren Aufgaben vor. 
            </p>    
        </div>
        <div class="textblock">
            <h2 class="h2-orange"> OS installieren</h2>
            <p>
                Man wird es kaum glauben, aber tatsächlich war das installieren eines geeigneten Betriebssystem auf dem Raspberry Pi aus technischer Hinsicht mit die größte Hürde.
                Die Version des Boards war hierbei so neu dass die derzeitige LTS Version von Raspberry OS noch einen veralteten Bootloader verwendete. 
            </p>    
        </div>
    </div>    
</template>

<script>
export default {
    name: 'LessonLearned',
    props: {}
}
</script>

<style>
#lesson {
    /* background-color: black; */
    width: inherit;
    height: inherit;
    align-content: center;
    padding-top: 2rem;
    margin-bottom: 2rem;
}

</style>
