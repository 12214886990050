<template>
    <div id="system" class="component-content">
        <div class="textblock textSystem">
            <p> 
                Um das Systemdiagramm zu <b>vergrößern klicken</b> Sie es bitte an.
            </p>
        </div>
            <img class="system" @click="scale" alt="Systemdiagram von timbre High-Level" src="../assets/Systemdiagramm-low-levelV3.png">
            <img class="system" @click="scale" alt="Systemdiagram von timbre Server" src="../assets/DatenübertragungV2.png">
    </div>    
</template>

<script>
import gsap from "gsap"
export default {
    name: 'Systemdiagramme',
    props: {},
    data(){
        return{
            scaleMode: false,    
        }
        
    },
    methods: {
        scale(){
            // var tl = gsap.timeline();
            //tl.to(".system:hover", {duration: .3, scale: 2});
            if(this.scaleMode == true){
                this.unscale();
            }
            else {
                var tl2 = gsap.timeline();
                tl2.to("#app", {duration: .3, scale: 2.5});    
                this.scaleMode = true;   
            }
     
        },
        unscale(){
            // var tl = gsap.timeline();
            // tl.to(".system", {duration: .3, scale: .5});
            var tl2 = gsap.timeline();
            tl2.to("#app", {duration: .3, scale: 1});        
            this.scaleMode = false;    
        }
    }
}
</script>

<style>
.system {
    width: 100%;
    height: auto;
    align-content: center;
    padding-top: 2rem;
    margin-bottom: 2rem;
}
.textSystem{
    width: 500px!important;
}
</style>
