<template>
    <div id="problem" class="">
        <p @mouseenter="scale" @mouseleave="scaleDown" class="subtext-yellow">
            Wie kann man
        </p>
        <p @mouseenter="scale" @mouseleave="scaleDown" class="subtext-turkis">
            mit anderen musizieren 
        </p>
        <p @mouseenter="scale" @mouseleave="scaleDown" class="subtext-yellow"> 
            wenn ein
            physisches Treffen nicht
            möglich ist?
        </p>
    </div>    
</template>

<script>
import gsap from "gsap"
export default {
    name: 'Problem',
    props: {},
    mounted() {
       
    },
    methods: {
        scale(){
            var tl = gsap.timeline();
            var tl2 = gsap.timeline();
            tl.to(".subtext-yellow:hover", {scale: 1.2, opacity: 1});
            tl2.to(".subtext-turkis:hover", {scale: 1.2, opacity: 1, color: "#D94A4A"});            
        },
        scaleDown(){
            var tl = gsap.timeline();
            var tl2 = gsap.timeline();
            tl.to(".subtext-yellow",{duration: .5, scale: 1}); 
            tl2.to(".subtext-turkis",{duration: .5, scale: 1 ,color: "#04BFAD"}); 
        }
    }
}
</script>

<style>
#problem {
    /* background-color: black; */
    width: inherit;
    height: inherit;
    align-content: center;
    padding-top: 2rem;
    margin-bottom: 2rem;
    
}
</style>
