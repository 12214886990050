<template>
    <div id="business" class="component-content">
        <div class="textblock business">
            <h2> Friends, Family, Fools</h2>

            <h3 class="h4-r"> Milestones</h3>        
                <p>
                    <ul>
                        <li  class="textBorder"> - Entwicklungsphase des Prototypen hin zu einem Marktreifen Produkt (1.&2. Quartal)</li>
                        <li  class="textBorder"> - Suche eines Gründungsberaters</li>
                        <li  class="textBorder"> - Aufbau der Social Media Präsenz</li>                                                
                    </ul>
                </p> 
            <h3 class="h4-r"> Finanzierung</h3>        
                    <h4 class="h4-r"> Mikrokredit:</h4>
                    <ul>
                        <li  class="textBorder"> Kreditrahmen: 25.000€</li>
                        <li  class="textBorder"> Tilgung: 48 Monate (6,9% Zinsen) / Einmalig: 130€ </li>
                        <li  class="textBorder"> Link: https://www.bmas.de/DE/Arbeit/Arbeitsfoerderung/Foerderung-der-Erwerbstaetigkeit/Mikrokredit/mikrokredit.html</li>                                                
                    </ul>

                    <h4 class="h4-r"> Eigenanteil: </h4>     
                    <p>
                        Rahmen: 6000€                                                
                    </p>                  
        </div>   
        <div class="textblock  business">
            <h2> Business Angel</h2>

            <h3 class="h4-r"> Milestones</h3>        
                <p>
                    <ul>
                        <li  class="textBorder"> - Erste Produktverkäufe</li>
                        <li  class="textBorder"> - Erste Kooperationen mit bekannten Influencern (Medium/Big-Player)</li>                                            
                    </ul>
                </p> 
            <h3 class="h4-r"> Finanzierung</h3>        
                    <h4 class="h4-r"> ERP-Gründerkredit - Startgeld</h4>
                    <ul>
                        <li  class="textBorder"> Kreditrahmen: 125.000€</li>
                        <li  class="textBorder"> Link: https://www.kfw.de/inlandsfoerderung/Unternehmen/Gr%C3%BCnden-Nachfolgen/F%C3%B6rderprodukte/ERP-Gr%C3%BCnderkredit-Startgeld-(067)/</li>                                                
                    </ul>     
                    <h4 class="h4-r"> Crowdlending</h4>
                    <ul>
                        <li  class="textBorder"> Kreditrahmen:20.000€ </li>
                        <li  class="textBorder"> Tilgung: variabel</li>                                               
                    </ul>                  
        </div>   
        <div class="textblock business">
            <h2> Seed Stage Capital</h2>

            <h3 class="h4-r"> Milestones</h3>        
                <p>
                    <ul>
                        <li  class="textBorder"> - Entwicklung weiterer timbre Geräte</li>
                        <li  class="textBorder"> - Entwicklung von Erweiterungen</li>
                        <li  class="textBorder"> - Aufbau des Premium Supports</li>                                                
                    </ul>
                </p> 
            <h3 class="h4-r"> Finanzierung</h3>        
                    <h4 class="h4-r"> ERP-Kapital für Gründung</h4>
                    <ul>
                        <li  class="textBorder"> Kreditrahmen: 500.000€</li>
                        <li  class="textBorder"> Link: https://www.kfw.de/inlandsfoerderung/Unternehmen/Gr%C3%BCnden-Nachfolgen/F%C3%B6rderprodukte/ERP-Kapital-f%C3%BCr-Gr%C3%BCndung-(058)/</li>                                               
                    </ul>                   
        </div>   

        <div class="textblock business">
            <h2> Early Stage Capital</h2>

            <h3 class="h4-r"> Milestones</h3>        
                <p>
                    <ul>
                        <li class="textBorder"> - Massenproduktion der Gehäuse durch Zulieferer</li>
                        <li class="textBorder"> - Massenproduktion von Platinen für Microcontroller und Audiointerface </li>
                        <li class="textBorder"> - Starker Ausbau der Akquisetätigkeiten</li>                                                
                    </ul>
                </p> 
            <h3 class="h4-r"> Finanzierung</h3>  
                    <h4 class="h4-r"> ERP-Förderkredit KMU</h4>      
                    <ul>
                        <li class="textBorder"> - Kreditrahmen: bis zu 25 Mio € (hier: 3.000.000€)</li>
                        <li class="textBorder"> - Tilgungsdauer: bis zu 20 Jahre </li>                                            
                        <li class="textBorder"> - Link: https://www.kfw.de/inlandsfoerderung/Unternehmen/Gr%C3%BCndung-und-Nachfolge/F%C3%B6rderprodukte/ERP-F%C3%B6rderkredit-KMU-(365-366)/</li>                                           
                    </ul>                  
        </div>                            
    </div>    
</template>

<script>
export default {
    name: 'Problem',
    props: {}
}
</script>

<style>
#business {
    /* background-color: black; */
    width: inherit;
    height: inherit;
    align-content: center;
    padding-top: 2rem;
    margin-bottom: 2rem;
}
.business{
    background-color: #d94a4a1e;
    margin-left: 2rem;
}
.textBorder {
    width: 400px;
}
</style>
