<template>
    <div id="inter" class="component-content">
            <audio id="audio">
                <source src="../assets/audio/All.wav" type="audio/wav">
            </audio> 
        <p class="textblock"> <b>Heben</b> oder <b>senken</b> Sie die Lautstärke indem Sie den Kreis ziehen oder zurücksetzen. </p>
        <div class="align-demos">
                
            <div class="demo">    
                <div 
                    @mousedown="startDrag" 
                    @mouseleave="setSize"
                    class="kreis"
                    >
                        Bitte Ziehen
                </div>

                <button class="reset" @click="play"> Play </button>
                <button class="reset" @click="reset"> Reset </button>
                <div id="pos" class="info" @click="show = !show"> i </div>
            </div>   
                <p v-if="show == true" id="pos2" class="textblock">
                Auf dem Interface von <b> timbre </b> wird die Lautstärke von Bandmitgliedern, als Kreis dargestellt. Diesen Kreis kann man durch betätigen und ziehen der Maustaste vergrößern. Achten Sie darauf wie sich die Lautstärke der Audiodatei ändert, wenn Sie die größe des Kreises ändern und dann die Tonspur abspielen.
                </p>
        </div>

    </div> 
</template>

<script>
import gsap from "gsap";
 
export default {
    
    name: 'Interaltion',
    props: {
        audioV: null,
        
    },
    data(){
        return {
            x: 1,
            tl: undefined,
            volume: .1,
            show: false,
        }
    },
    methods: {
        startDrag() {
            this.drag = true;
            console.log("dragged!");
            this.dragEnd =  true;
        },
        setSize() {
            if(this.drag == true) {
                this.tl = gsap.timeline();
                this.tl.to(".kreis",{duration: 1, scale: 2});
                console.log("sizeSet!" + this.x);  
                if(this.audio){
                    this.audio.pause();  
                }  
                this.setVolume();                
            }

        },
        setVolume(){
            this.volume = .3;
        },
        play(){
            console.log("Play");
            // Audiodatei wird bereitgestellt
            this.audio = document.getElementById("audio");
            // Set Volume
            this.audio.volume =  this.volume;
            console.log(this.audio);
            // Audio Volume 
            this.audio.play();

        }, 
        reset() {
            //this.tl = gsap.timeline();
            this.audio.pause();
            this.tl.to(".kreis",{duration: 1, scale: 1});
            this.volume = .1;
            console.log("sizeSet!" + this.x); 

        }
    }
}
</script>

<style>
#lesson {
    /* background-color: black; */
    width: inherit;
    height: inherit;
    align-content: center;
    padding-top: 2rem;
    margin-bottom: 2rem;
}
.kreis {
    border-radius: 50%;
    height: 5rem;
    width: 5 rem;
    margin-right: 5rem;
    background-color: #04bfad;
}
.demo {
    display: flex;
    justify-content: space-around;
}
.align-demos {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 90%;
    height: auto;
    align-content: center;
    padding-top: 2rem;
    margin-bottom: 2rem;
}
</style>
