<template>
    <div id="projectM" class="component-content">

        <div class="textblock">            
            <h2 class="h2-turkis"> Methode </h2>
            <p>
                Scrum nach GitLab-Interpretation.
            </p>
            <br>
            <h2 class="h2-turkis"> Umsetzung des Projektmanagements </h2>
            <p>
                Um die erwähnte Scrum- Methode umzusetzen habe ich mich in diesem Projekt an der Interpretation von GitLab orientiert.
                GitLab, vorwiegend bekannt für seine Produkte zur Versionskontrolle, bietet auch ein modernes Ticketsystem. Der Vorteil hierbei liegt in der Verknüpfung von Versionskontrolle und Ticketsystem. So werden alle Aktionen im Rahmen des Projektes hinterlegt. So ist das Wiederherstellen von vorherigen Projekt-Zuständen über den Programmcode hinaus möglich.
                Auch ist eine Dokumentation somit wesentlich prä- ziser und dank einer Er- weiterung zum Export von Projekt-Momentaufnahmen schnell möglich.
            </p>
        </div>  

        <div class="textblock">
            <h3> Ticketsystem </h3>
            <p>
                Zu Beginn des Projektes habe ich mich zunächst für trello als Projektmanagement Tool erzeugt. Es sollte vorrangig als Kanban Board dienen und einen Überblick zu den anstehenden Aufgaben geben.
                In einem Interview mit Michael Müller (Project Manager) konnte ich weitere Einblicke in die Entwicklung von sogenannten Embedded Systems erhalten. Dieser wies mich auf die Vorteile der Nutzung eines Ticketsystem hin, dass die Möglichkeit bietet Sprints anzulegen.
                So können einige Tickets aus dem Backlog in einen Sprint geschoben werden. Dies hilft einen groben Überblick über den Projektverlauf zu gewinnen. Auch werden Sprint-Planning Events kürzer und somit auch Tickets seltener im Backlog “vergessen”.
            </p>
        </div>  

        <div class="textblock">
            <h3> Ticket-Stadien </h3>
            <p>
                <ul>
                <li>
                    <h5> Unstarted: </h5>
                    Das Ticket wurde einem Milestone zugewiesen, aber noch keiner Person. 
                </li>
                <li>
                    <h5> Ongoing: </h5>
                    Das Ticket wurde sowohl einem Milestone als auch einer Person zugewiesen. Es wurde jedoch noch nicht mit der Bearbeitung begon- nen.
                </li>
                <li>
                    <h5> Completed: </h5>
                    Das Ticket wurde zugewie- sen und abgeschlossen.
                </li>
                </ul>
            </p>
        </div>  

        <div id="color1" class="textblock">
            <h3 class="h3-orange"> Sprint-Länge: </h3>
            <p>
                1 Woche
            </p>

            <h3 class="h3-orange"> Summe der Sprints: </h3>
            <p>
                10 Wochen
            </p>

            <h3 class="h3-orange"> Beginn eines Sprints: </h3>
            <p>
                Montag (0:00 am)
            </p>
          
            <h3 class="h3-orange"> Ende eines Sprints: </h3>
            <p>
                Sonntag (0:00 pm)
            </p>
        
            <h3 class="h3-orange"> Beginn eines Sprints: </h3>
            <ul>
                <li> - Scrum Retrospektive (Sonntag Abend) </li>
                <li> - Sprint-Planning (Montag) </li>        
            </ul>
        </div>  

    </div>    
</template>

<script>
export default {
    name: 'Projektmanagement',
    props: {}
}
</script>

<style>
#projectM {
    width: inherit;
    height: inherit;
    align-content: center;
    padding-top: 2rem;
    margin-bottom: 2rem;
}
#color1 {
    background-color: #f2870510;
}
</style>
