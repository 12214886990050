<template>
    <div id="form" class="component-content">
        <div class="textblock"> 
            <h2 class="h2-orange"> Wesen</h2>
            <p>
                Ich bin ein Aufnahmegerät, dass mit dem Internet verbunden ist.
                Ich bin ein vernetztes Aufnahmegerät.
            </p>

            <h3> Ausrichtung:</h3>
            <p>
                Das Interface des Gerätes mit seinen Hauptfunktionen muss den Nutzenden zugewandt sein. Hierzu gehören Eingänge (Inputs), Lautstärkeregler, Entscheidungs-Buttons/Potentiometer, Mixing-Potentiometer/Buttons und LEDs.
            </p>

            <h3> Bedienung:</h3>
            <p>
                Für die Bedienung während des Musizierens werden keine Displays verwendet, damit der Fokus auf dem Hörsinn liegt, welcher durch den taktilen Sinn und nuanciert eingesetzte LED-Anzeigen ergänzt wird (Fußschalter). Grundeinstellungen zur akustischen Raumposition anderer Mitglieder (Stereopanning, Lautstärke), sowie das Annehmen/Ablehnen von Einladungen zu Jamsessions werden über den Touchscreen gesteuert.
                Die Kommunikation mit anderen Bandmitgliedern geschieht über aktives Hören, also auf das hören was gespielt wird.
                Entscheidungen werden taktil über Fußschalter getroffen. Dabei lassen sich alle Entscheidungen aus dem Menu auch mit dem Fuß steuern! Nach dem Ampelsystem werden Bestätigungen mit grüner Farbe assoziiert und Ablehnung mit roter Farbe.
                LEDs, Buttons, Potentiometer, Display sind auf Basis des Symbolischen Interaktionismus gestaltet (vgl. Eisewicht & Hitzler & Schäfer, 2021, S.84). 

            </p>
 
            <h3> Körperbezug:</h3>
            <p>
                Man fühlt den Unterschied zwischen den Entscheidungs-Buttons.
                Der Ton wird mit echtem Stereopanorama und ausgeglichenem Frequenzspektrum übertragen.
            </p>

            <h3> Stabilität/Belastbarkeit:</h3>
            <p>
                Das Gerät soll für einen Musiker Alltags-geeignet sein sowohl für den Studioeinsatz, als auch auf der Bühne, oder beim Gitarrespielen am Lagerfeuer.
                Weitere Allwetter Tauglichkeit wird nicht vorausgesetzt.
            </p>

            <h3> Präzission:</h3>
            <p>
                Das Produkt muss Präzision ausdrücken, da es auch für den professionellen Gebrauch gedacht ist. Dies äußert sich in:
                <ul>
                    <li> - Potentiometer lassen sich sehr fein und genau bewegen. </li>
                    <li> - LEDs zur Anzeige der Lautstärke reagieren sehr schnell und akzentuiert. </li>  
                </ul>
            </p>

            <h3> Standfunktion:</h3>
            <p>
                Das Gerät muss einen sicheren Stand gewährleisten, da Instrumente und Mikrofone angeschlossen werden. Diese können mitunter schwer sein und ein Verschieben und Umfallen des Gerätes begünstigen, welches die Nutzungserfahrung, wegen erneutem Aufstellen, verschlechtern kann.
            </p>
        </div>

    </div>    
</template>

<script>
export default {
    name: 'Formfindung',
    props: {}
}
</script>

<style>
#form {
    /* background-color: black; */
    width: inherit;
    height: inherit;
    align-content: center;
    padding-top: 2rem;
    margin-bottom: 2rem;
}

</style>
