<template>
  <div id="app">

<meta name="viewport" content="width=device-width, initial-scale=1.0">

    <body>
       <!-- :name="items" bindet die Daten von Eltern und Kind Komponente -->
      <ContentLoader 
        :name="items" 
        :activeItem="clickedItem||{}"
        @clickedItem="getClickedItem" 
        class="content"
      />
    </body>

    <footer >
       <!-- 
        - :name="items" bindet die Daten von Eltern und Kind Komponente 
        - Wenn das Element @clickedItem aktiviert wird (Klick in Kind-Komponente) wird getClickedItem() ausgeführt und die Daten werden geladen.
       -->

      <NavLine 
        :name="items" 
        @clickedItem="getClickedItem" 
        class="nav"
      /> 

    </footer>
  </div>
</template>

<script>
// Import von Komponenten 
import ContentLoader from './components/ContentLoader.vue'
import NavLine from './components/NavLine.vue'
import { gsap } from "gsap";

export default {
  name: 'App',
  props: {
  },

  // Kind-Komponenten die geladen werden
  components: {
    ContentLoader,
    NavLine,
  },

  data: function(){
    return {
      items: [
        { pos: 1, name: 'Trailer' },
        { pos: 2, name: 'Recherche' },
        { pos: 3, name: 'Problem' },
        { pos: 4, name: 'Idee' },
        { pos: 5, name: 'Manifesto' },
        { pos: 6, name: 'Zielgruppe' },
        { pos: 7, name: 'Komponenten' },
        { pos: 8, name: 'Systemdiagramme' },
        { pos: 9, name: 'Formfindung' },
        { pos: 10, name: 'ProofofConcept' },
        { pos: 11, name: 'Interaktion' },        
        { pos: 12, name: 'Businessmodell' },
        { pos: 13, name: 'Marketing' },
        { pos: 14, name: 'Projektmanagement' },
        { pos: 15, name: 'Prototyp' },
        { pos: 16, name: 'LessonLearned' },
        { pos: 17, name: 'Ausblick' },
      ],
      clickedItem: '',
      out: false,
       
    }
  },

  mounted () {
    // Standard Animation für das Initiale laden von Komponenten
    this.fadeIn();
    this.start();
    
    this.fadeOut();
    
  },
  methods: {
    
    getClickedItem(value) {
      // lädt die Daten aus Kind-Komponente nach dem @clickedItem Trigger.
      // Daten aus Kindkomponente werden in this.clicked zwischengespeichert
      this.clickedItem = value;
      // this.component = this.clickedItem.name;
      this.out = true;
    },

    fadeIn() {
      var tl = gsap.timeline(); //create the timeline
        tl.to("#app", {opacity:0, duration: 0})
          .to("#app", {opacity: 1, duration: 1});
    },

    start()  {
      var tl1 = gsap.timeline();
      var tl2 = gsap.timeline();
      tl2.from(".subtitel",{duration: .7, x: -1000, opacity: 0})
      tl1.from(".titel",{duration: .7, x: 1000, opacity: 0});
    }
  },
  fadeOut(){
    var tl1 = gsap.timeline();
    tl1.to(".titel",{duration: .3, x: -1000, opacity: 0});
  }
}
</script>

<style>
/* Typographie */
@font-face {
  font-family: 'VlaBold';
  src: url('./assets/fonts/VLANÈLLA BOLD.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

#app {
  font-family: 'VlaBold', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #cccccc;
  margin-top: -1rem;
  margin-left: -1rem;
}

footer {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}

.component-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 90%;
    height: auto;
    align-content: center;
    padding-top: 2rem;
    margin-bottom: 2rem;
}

button {
    display: inline-block;
    border-radius: 4px;
    background-color: #ffffff00;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    font-weight:lighter;
    border: 1px solid #cccccc;
    color: #b9b9b9;
    text-shadow: 1px -1px 2px black, 5 5 5px rgb(255, 255, 255), -5 -5 5px rgb(rgb(0, 0, 0), green, blue);
    text-align: center;
    width: 7em;
    height: 2em;
    width: auto;
    height: fit-content;
    margin: .5em;
    opacity: .8;
}

.card {
  width: 400px;
  height: fit-content;
  text-align: left;
  padding-left: 1rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  border-radius: 10px;
  background-color: #04bfac3a; 
}
.card-white {
  width: 400px;
  height: fit-content;
  text-align: left;
  padding-left: 1rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;  
  border-radius: 10px;
  color: #1a1a1a; 
  background-color: #cccccc; 
}
.textblock{
  width: 400px;
  height: fit-content;
  text-align: left;
  padding-left: 1rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-radius: 10px;
}

.choice {
  border-radius: 15px;
}

.img {
    width: 10rem!important;
    height: auto!important;
}
.imgF {
  width: 20rem;
  height: auto;
  transform: rotate(270deg);
      margin-top: 4rem;
}

.imageblock {
  width: 400px;
  text-align: left;
  padding-left: 2rem;
  padding-bottom: 2rem;
}

.videoblock {
  width: 400px;
  height: auto;
  text-align: left;
  padding-left: 2rem;
  padding-bottom: 2rem;
}

/* Überschriften */
.h2-orange {
  color: #f28705;  
} 

.h2-turkis {
  color: #04bfad;  
} 

h3 {
  margin: 40px 0 0;
  color: white;
  color: #1a1a1a;
  background-color: #04bfad;  
}

.h3-orange {
  background-color: #f28705;
}

h4 {
  color: white;
  background-color: #04bfad;
}
h5 {
  font-size: 14pt;
}
.h4-t {
  background-color: #04bfad!important;
  color: #1a1a1a;
  
}
.h4-o {
  background-color: #f28705!important;
  color: #1a1a1a;
  
}

.h4-r {
  background-color: #d94a4a;
  color: #1a1a1a;
}

.h5-t {
  font-size: 16 pt;
  color: #04bfad;
}

.subtext-yellow {
  font-size: 20pt;
  font-family: Helvetica, sans-serif;
  color: #F2D335;
  
}

.subtext-orange{
  font-size: 20pt;
  font-weight: bold;
  color: #f28705;

}
.subtext-turkis{
  font-size: 20pt;
  font-weight: bold;
  color: #04bfad;

}
.info {
  border-radius:50%;
  font-size: 10pt;
  width: 15px;
  height: 15px;
  border: 1px solid #cccccc;
  color: #b9b9b9;
  text-shadow: 1px -1px 2px black, 5 5 5px rgb(255, 255, 255), -5 -5 5px rgb(rgb(0, 0, 0), green, blue);
  text-align: center;
}
.highlight {
  color:#cccccc;
  opacity: 0.4;
}
b {
  color: #04bfad;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  /* width: 400px; */
  word-wrap: break-word;
  margin: 0 10px;
  
}
a {
  color: #42b983;
}

/* Basiseinstellungen DOM */
body > header {
  position: fixed;
  top: 0;
}

body > footer {
  position: fixed;
  bottom: 0;
}
</style>
