<template>
  <div class="content">
    
    <h1 class="subtitel" :activeItem="this.component= this.activeItem.name">
      
      {{activeItem.name||"Herzlich Willkommen!"}}
    </h1>
    <h1 class="titel"> timbre</h1>
    <!-- Content ändern durch klick-->
    <!-- <button @click="component = activeItem.name"> Aktualisieren Komponente</button> -->
    <!-- <button @click="changeContent"> Aktualisieren Komponente</button> -->

    <div class="content-align">
      
      <KeepAlive>
        <component :is="component"/>
      </KeepAlive>
    </div>

  </div>
  
</template>

<script>
import Trailer from './Trailer.vue';
import Recherche from './Recherche.vue'
import Problem from './Problem.vue'
import Idee from './Idee.vue'
import Manifesto from './Manifesto.vue'
import Zielgruppe from './Zielgruppe.vue'
import Komponenten from './Komponenten.vue'
import Systemdiagramme from './Systemdiagramme.vue'
import Formfindung from './Formfindung.vue'
import ProofofConcept from './ProofofConcept.vue'
import Businessmodell from './Businessmodell.vue'
import Marketing from './Marketing.vue'
import Projektmanagement from './Projektmanagement.vue'
import Prototyp from './Prototyp.vue'
import LessonLearned from './LessonLearned.vue'
import Ausblick from './Ausblick.vue'
import Interaktion from './Interaktion.vue'
// import gsap from "gsap"
export default {
  name: 'ContentLoader',

  // Daten die aus Eltern-Komonente erhalten wird
  props: {
    name: Array,
    activeItem: Object,
    show: Boolean
  },

  // Rgistrierung externer verwendete Komponenten
  components: {
    Trailer,
    Problem,
    Recherche,
    Idee,
    Manifesto,
    Zielgruppe,
    Komponenten,
    Systemdiagramme,
    Formfindung,
    ProofofConcept,
    Interaktion,
    Businessmodell,
    Marketing,
    Projektmanagement,
    Prototyp,
    LessonLearned,
    Ausblick
  },

  data: function() {
    return {
    component: undefined,
    }
  },

  methods: {
    // Click-Animation
    changeContent (){
      // component: Problem,
      this.component= this.activeItem.name;
      console.log("Content changed");
    },
  }  
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content {
  display: inline-block;
  width: 100%;
  padding: 3rem;
  /* background-color: rgba(0, 0, 0, 0.04); */
  background-color: #1a1a1a;
  border-radius: 2px;
}
.content-align {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}
h1 {
  padding-top: 3rem;
  margin-bottom: 3rem;
  font-weight: bolder;
  font-size: 32pt;

  
}
.titel {
  color: #04fbad;
  font-weight: lighter;
  margin-top: -5.5rem;
  font-size: 24pt;
  
}
.subtitel {
  color: #F2D335;
}
</style>
