<template>
    <div id="poc" class="component-content">
         
        <div class="design component-content">     
            <div @mouseenter="scaleImageT" @mouseleave="scaleDownImage" class="card-white ">     
                <h2> Poc 1: Der Tastsinn</h2>  
                <h5> Funktion: </h5>     
                <p>
                    Bei diesem Teil PoC soll überprüft werden, ob es möglich ist die Schalter nur über den den sensorischen Sinn in den Fingern zu unterscheiden.
                </p>
                <h5> Aufbau: </h5>
                <p>
                    Mit Hilfe eines Kugelschreibers werden die Symbole der Entscheidung Buttons auf ein Papier mit starkem Druck geschrieben. 
                </p>       
                <!-- Video zum Tastsinn -->
                <video class="fitCard" controls>
                    <source src="../assets/videos/poc1OG.mp4" type="video/mp4">
                    <source src="movie.ogg" type="video/ogg">
                    Your browser does not support the video tag.
                </video>         
                <h5> Durchführung: </h5> 
                <p>
                    Testperson muss n mal raten welcher Button gewählt wurde. Die Fehler/Treffer werden dann notiert. 
                    Das erkennen des gezogenen Symbols gilt als gegeben wenn die Trefferquote bei >50% liegt.
                    Es sei n = 3.
                </p>                
                <h5> Erkenntnis: </h5> 
                <ul>
                    <li> - Das gezogene Symbol wurde 3 von 3 mal richtig erkannt. </li>
                    <li> - Der PoC 1 gilt als bestanden. </li>
                </ul>
            </div>
            <div @mouseenter="scaleImageT" @mouseleave="scaleDownImage" class="card-white ">     
                <h2> Poc 2: Die Position</h2>  
                <h5> Funktion: </h5>     
                <p>
                    Bei diesem PoC soll überprüft werden in welchem Winkel ein Bildschirm auf dem Boden platziert werden muss, um den Inhalt bestmöglich zu sehen. 
                </p>
                <h5> Aufbau: </h5>
                <p>
                    Der Bildschirm in diesem Fall ein Touchscreen ist hierbei auf dem Boden platziert, während die Person gegenüber auf einem Stuhl sitzt. Der Touchscreen wird hierbei 15 cm von der Fußspitze der Testperson in den folgenden 4 Positionen platziert:
                    <ul>
                    <li> - horizontal zum Boden </li>
                    <li> - im 45°-60° Winkel zum Boden </li>
                    <li> - im 90°-Winkel zum Boden </li>
                    </ul>
                    Um die Lesbarkeit zu bewerten wird ein Standard- Testbild verwendet.
                </p>     
                <img class="fitCard" src="../assets/poc2-60-2.png"/>            
                <h5> Durchführung: </h5> 
                <p>
                    Die Position bei der man den größten Bereich des Bildschirms sehen kann wird für den späteren Prototyp verwendet. 
                    Um eine Platzierung rein nach persönlicher Präferenz zu vermeiden, wurde aus dem Position der Testperson Fotos vom Touchscreen geschossen. Diese wurden im Anschluss als Entscheidungsgrundlage herangezogen.
                </p>                
                <h5> Erkenntnis: </h5> 
                <ul>
                    <li> - horizontal zum Boden:
                    Hier waren die Zahlen bis zur 6. Markierung lesbar. Jedoch war hierfür ein krampfhaftes Senken des Kopfes nötig wodurch 
                    angenehmes Musizieren
                    mittelfristig nicht möglich gewesen wäre.</li>

                    <li> - Im 45°- 60° Winkel zum Boden:
                    Die Zahlen bei dieser Einstellung waren bis zur 7.  Markierung lesbar. Bei einem Winkel von genau 45° entstanden allerdings Reflexionen durch das Zimmerlicht. Dieses Phänomen kann bei allen Einstellungen (0°, 60°, 90°) entstehen war jedoch bei 45° am präsentesten. </li>
                   
                    <li> - Im 90°-Winkel zum Boden:
                    Diese Option war mit Abstand die ungeeignetste, da Zahlen nur bis zur 4. Markierung lesbar waren.
                    Aus den verschiedenen Einstellungen des Displays entstand schnell der Eindruck, das eine Einstellung von 45°-60° die beste Lesbarkeit bot. Schlussendlich habe ich mich für eine Einstellung um die 60° entschieden um mögliche Reflexionen zu minimieren.</li>

                </ul>       
            </div>
            <div @mouseenter="scaleImageT" @mouseleave="scaleDownImage" class="card-white ">     
                <h2> Poc 3: Das Verhältnis</h2>  
                <h5> Funktion: </h5>     
                <p>
                    Dieser Teil PoC soll ein besseres Verständnis über die Größe des Display-Interfaces und damit verbunden auch der Touch-Gesten geben. 
                </p>
                <h5> Aufbau: </h5>
                <p>
                    Prototypische Gestaltung eines Mockups in den Dimmensionen des Touchscreens mit Kreisen zur Steuerung der Lautstärke (größer/kleiner zoomen) und des Stereo-Pannings. (verschieben nach links/rechts) Die Umsetzung wird dabei zunächst in Adobe XD implementiert.
                </p>  
                <!-- Video von PoC3     -->
                <video class="fitCard" controls>
                    <source src="../assets/videos/poc3.mp4" type="video/mp4">
                    <source src="movie.ogg" type="video/ogg">
                    Your browser does not support the video tag.
                </video>             
                <h5> Durchführung: </h5> 
                <p>
                    Testpersonen bekommen die Aufgabe die Lautstärke und das Panning durch zoomen und verschieben des Avatars zu ändern.
                </p>                
                <h5> Erkenntnis: </h5> 
                <p>
                    Durch diese Visualisierung des Avatars zur Steuerung von Lautstärke und Stereopanorama konnte ich feststellen, dass
                    Eine maximale Größe der Avatare notwendig ist um den anderen Avataren genug Platz zu lassen.
                </p>  
        </div>
        </div>
            <div class="technik component-content">     
            <div @mouseenter="scaleImageO" @mouseleave="scaleDownImage" class="card-white ">     
                <h2> Poc 1: Kompatibilität</h2>  
                <h5> Funktion: </h5>     
                <p>
                    Kompatibilitätstest der Audio Treiber des Audio Interface auf einer Debian Distribution (Raspberry OS) 
                </p>
                <h5> Aufbau: </h5>
                <p>
                    Es wird ein Raspberry Pi 4 Model B per USB-Kabel an ein Native Instruments Komplete Audio 1 Interface angeschlossen. 
                </p>          
                <img class="fitCard" src="../assets/succes-r-os.png"/>
                <h5> Durchführung: </h5> 
                <p>
                    Es wird getestet ob die Ein/-Ausgänge des Audio Interfaces richtig erkannt werden und ob eine beliebige Audioquelle Störungsfrei abgespielt werden kann.
                </p>                
                <h5> Erkenntnis: </h5> 
                <p>
                    Die Befürchtung vor Durchführung des PoC 1 meinerseits war, dass durch unzureichende oder fehlende Treiberkompatibilität Probleme bei der Echtzeitwandlung des Audiosignals entstehen. Diese Befürchtung wird durch negative Erfahrungen mit Grafik-Treibern auf Debian/Unix-Systemen begründet. Diese Probleme könnten sich durch sogenannte Audio Drop-Outs bemerkbar machen. Auch wäre möglich, dass das Audiointerface gar nicht erst erkannt wird.  
                    Das Audiointerface wurde bewusst wegen den hochwertigen Wandlern gewählt, jedoch auch wegen der angegebenen Class-Comliance.

                    Tatsächlich war dieser PoC1 mit Anschluss des Audiointerfaces an den Raspberry Pi bereits bestanden.

                    Die Audioausgabe wurde anhand eines hochauflösenden Youtubestreams getestet, welcher ohne Probleme wiedergegeben wurde.
                    Individuelle Unterschiede in der Betonung oder Verminderungen bestimmter Frequenzbereiche durch das Audiointerface wurden hierbei nicht beachtet. 
                </p>                
            </div>
            <div @mouseenter="scaleImageO" @mouseleave="scaleDownImage" class="card-white ">     
                <h2> Poc 2: Rechenleistung</h2>  
                <h5> Funktion: </h5>     
                <p>
                    Leistungstest der Kombination von Raspberry und Komplete Audio 1.
                    Im Fokus steht hierbei die Frage ob ein Raspberry Pi leistungsstark genug für den Betrieb einer DAW ist. 
                    Laut Hersteller ist die Nutzung ab einem Arbeitsspeichervolumen von  4GB  möglich. 
                    Der Aufpreis für einen Chip mit 4GB RAM lässt sich nur durch einen für die Nutzenden spürbaren Leistungszugewinn rechtfertigen. 
                    Um zu überprüfen, ob dieser Leistungsunterschied existiert, wurde der PoC 2 mit einem Chip mit 2GB RAM durchgeführt.
                </p>
                <h5> Aufbau: </h5>
                <p>
                    Von diesem System aus Micro Controller und Audiointerface aus soll die Digital Audio Workstation Reaper gestartet werden. 
                </p> 
                <img id="komp" class="fitCard" src="../assets/raspberry-komplete1.png"/>                
                <h5> Durchführung: </h5> 
                <p>
                    Zur Durchführung des PoC muss lediglich die DAW „Reaper“ gestartet werden und testweise ein Projekt mit 7 Spuren angelegt werden. Der PoC gilt als bestanden wenn keine Audiostörungen wie Audio Drop-Outs oder Systemabstürtze vorkommen.
                    Zunächst wurde mit einer
                    Instrumentenspur (Virtuelles Instrument) getestet, später mit 8 Spuren. 
                </p>                
                <h5> Erkenntnis: </h5> 
                <p>
                    Es traten keine Audio Drop-Outs auf.
                </p>             
            </div>
            <div @mouseenter="scaleImageO" @mouseleave="scaleDownImage" class="card-white ">     
                <h2> Poc 3: Latenz</h2>  
                <h5> Funktion: </h5>     
                <p>
                    Machbarkeits-/Leistungstest des Audiostreams 
                    Bei diesem PoC ging es um die Frage ob Audiostreaming möglich ist. Diese Frage wurde teilweise schon durch die Recherche gezeigt [Digitale Transformation]. Interessant ist deshalb nicht nur ob ein Audiostreming möglich ist, sondern auch mit welcher Latenz.
                    Erstrebenswert wäre eine Latenz unter 50ms. 
                </p>
                <h5> Aufbau: </h5>
                <p>
                    Auf dem bereits zuvor genannten System wird das Konkurrenzprodukt sonoBus installiert und getestet.
                </p>  
                <img id="k1" class="fitCard" src="../assets/prototyp-it1.png"/>               
                <h5> Durchführung: </h5> 
                <p>
                    Bei diesem Test wird hauptsächlich die Machbarkeit der Übertragung eines Audiostreams überprüft. Nebenbei wird ein Einblick in die Latenzwerte bei dieser Form der Audioübertragung möglich. 
                </p>                
                <h5> Erkenntnis: </h5> 
                <p>
                    Ein Audiostreaming ist in dieser Konstelation durchaus möglich. Jedoch ist zu erwähnen das in dem nicht repräsentativen Ergebnis eine Latenz von 53,6 ms entstand.
                </p>           
            </div>
        </div>

    </div>    
</template>

<script>
import gsap from "gsap"
export default {
    name: 'ProofOfConceot',
    props: {},
    data(){
        return{
           
        }
    },
    methods: {
        scaleImageT() {
            var tl = gsap.timeline();
            tl.to(".card-white:hover",{duration: .5, scale: 1.1});
            var tl2 = gsap.timeline();
            tl2.to(".content",{duration: .5, backgroundColor: "#f28705"});
        },
        scaleImageO() {
            var tl = gsap.timeline();
            tl.to(".card-white:hover",{duration: .5, scale: 1.1});
            var tl2 = gsap.timeline();
            tl2.to(".content",{duration: .5, backgroundColor: "#04bfad"});
        },
        scaleDownImage() {
            var tl = gsap.timeline();
            tl.to(".card-white",{duration: .5, scale: 1});
            var tl2 = gsap.timeline();
            tl2.to(".content",{duration: .5, backgroundColor: "#1a1a1a"});              
        }
    }
}
</script>

<style>
#poc {
    /* background-color: black; */
    width: inherit;
    height: inherit;
    align-content: center;
    padding-top: 2rem;
    margin-bottom: 2rem;
}
.fitCard {
    width: 100%;
    height: auto;
}
#komp{
    transform: rotate(270deg)!important;
    height: 100%;
    width: 70%;
    margin-left: 15%;
    margin-right: 50%;
}
#k1{
    transform: rotate(270deg);
    width: 50%;
    margin-left: 25%;
    margin-right: 50%;
}
</style>
