<template>
  <div class="nav-line">
    <!-- Einzelne Navigationsknoten -->
    <ul>
      <!-- Daten an Eltern-Komponente zurückschicken $emit(Funktion,Daten) -->
        <li 
        v-for="(value, index) in name" 
        v-bind:key="index"
        
        @click="changeChapter(value)"
        @mouseenter="hovered"
        @mouseleave="unhovered"
        class="nav-item"
        >
        <!-- {{value.pos}} -->
        {{value.name}}
        </li>  
    </ul> 
  </div>
</template>

<script>
import { gsap } from "gsap";

export default {
  name: 'NavLine',

  // Daten die aus Eltern-Komonente erhalten wird
  props: {
    name: Array,
  },

  data() {
    return {
      storyPos: 1,
    }
  },
  
  methods: {
    changeChapter(value){
      // Diese Funktion dient der aktualisierung der angezeigten Komponente.
      this.$emit('clickedItem', value);
      // Triggert Click-Animation
      var tl = gsap.timeline();
      tl.to(".nav-item:active", {color: "#04bfad", duration: .3});
    },

    hovered(){
      // Diese Funktion löst Animationen aus sobald ein nav-item "gehovert" wird.
      var tl = gsap.timeline();
      tl.to(".nav-item:hover", {scale: 1.2, opacity: 1});
    },

    unhovered() {
      // Diese Funktion löst Animationen aus immer wenn kein nav-item "gehovert" wird.
      var tl = gsap.timeline();
      tl.to(".nav-item", {scale: 1, opacity: 0.6});
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nav-line {
  padding-bottom: 10rem;
  
  height: 4rem;
  background-color: rgba(204, 42, 42, 0);
  z-index: 6;
  color: #1a1a1a;
}
.nav-item-list {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: cyan;
  text-align: center;
  color: rgb(110, 110, 110);
}
.nav-item {
  text-align: center;
  background-color: inherit;
  opacity: 0.6;
  color: inherit;
  font-size: 12pt;
  font-weight: normal;
  flex: auto;
  margin-top: 0rem;
  padding: 1.3rem;
}
.nav-item:first-child{
  /* color:blue; */
  /* opacity: 1; */
}
/* ACTIVE */
.nav-item:active {
  color: #04bfad;
}

</style>
