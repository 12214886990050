<template>
    <div id="manifesto" class="component-content">
        
        <div class="textblock">
            <h2 class="h2-orange"> Qualität und Agilität </h2>
            <p>
                Die Qualität der Audioaufnahme und vom timbre im allgemeinen sollen professionell sein. Außerdem soll das Produkt agil sein. Dies gilt sowohl bezüglich der Einsatzmöglichkeiten, als auch der Geschwindigkeit zur Verarbeitung und Übertragung von Audio-/MIDI-Daten.
            </p>
        </div>         
        <div class="textblock">              
            <h2 class="h2-orange"> Für den Musiker gemacht </h2>
            <p>  
                Online Musizieren soll unabhängig der eigenen Ausstattung möglich sein. Die musizierende Person benötigt lediglich einen Tonerzeuger, eine Internetverbindung und timbre. Das angebotene Gerät soll dabei auch für Amateurmusiker erschwinglich sein. Außerdem soll das Gerät an verschiedenen Orten nutzbar sein.
            </p>        
        </div>    
        <div class="textblock">
            <h2 class="h2-orange"> Hören im Mittelpunkt </h2>
            <p>
                Das kollaborative Hören und die Bildung von musikalischen Bedeutungszuweisungen dienen als Hauptkommunikationsmittel. Verbale und Audiovisuelle Kommunikation steht im Hintergrund.
            </p>
        </div>
        <div class="textblock">
            <h2 class="h2-orange"> Inklusion </h2>
            Das Musizieren mit timbre soll Inklusiv sein und neue Formen der Inklusion fördern.
        </div>

        <div class="textblock">
            <h2 class="h2-orange"> Gemeinsame und direkte Entscheidungen treffen </h2>
            <p>
                Musiker/innen sollen Entscheidungen direkt und gemeinsam treffen können. Dabei werden Vorgegebene Entscheidungsverfahren angeboten um eine schnelle Entscheidung beim Musizieren zu erhalten.
            </p>
        </div >

        <div class="textblock">
            <h2 class="h2-orange"> Spaß!</h2>
            <p>
                Die Musizierenden sollen Spaß beim musizieren Zusammen haben! Frustration-Momente sollen vermieden werden.
            </p>            

        </div>
    </div>    
</template>

<script>

export default {
    name: 'Manifesto',
    components: {
        
    },
    props: {}
}
</script>

<style>
#manifesto {
    /* background-color: black; */
    display: flex;
    width: fit-content;
    height: inherit;
    align-content: center;
    padding-top: 2rem;
    margin-bottom: 2rem;
}

</style>
