<template>
    <div id="zielgruppe" class="component-content">
        
        <div class="textblock" >
            <h2> Wer ist die Zielgruppe?</h2>
            <p> Die erste Iteration zur Ziel- gruppe fand zeitgleich mit der Entwicklung der Idee statt. Hierbei waren alle Musiker, welche geminsam musizieren im Fokus. Da jedoch gerade große En- sembles, Big Bands und Orchester ganz eigene Herrausforderungen beim gemeinsamen musizieren überwinden müssen habe ich diese erstmal ausge- schlossen. So reduzierte sich die Zielgruppe auf Musiker die in Bands zu- sammenarbeiten.</p>
        </div>

        <div class="textblock" >
            <h2> Was tut die Zielgruppe?</h2>
            <p> In der zweiten Iteration stand die Frage im Raum, welche Aktionen/Interaktio- nen von der Zielgruppe für gewöhnlich durchgeführt werden und auch mit timbre möglich sein sollen. Diese sind,</p>
            <ul>
                <li class="highlight" @mouseenter="highlightStart" @mouseleave="highlightEnd">
                   - In Bands jammen
                </li>
                <li class="highlight" @mouseenter="highlightStart" @mouseleave="highlightEnd">
                   - In Bands aufnehmen 
                </li>
                <li class="highlight" @mouseenter="highlightStart" @mouseleave="highlightEnd">
                   - Auftritte streamen 
                </li>
                <li class="highlight" @mouseenter="highlightStart" @mouseleave="highlightEnd">
                  -  Private Musikerstellung 
                </li>
                <li class="highlight" @mouseenter="highlightStart" @mouseleave="highlightEnd">
                   - Professionelle Musikerstellung 
                </li>
            </ul>
        </div>        

        <div class="textblock" >
            <h2 class="h2-orange" > Expeditiven.</h2>
            <p> Die ambitio- nierte kreative Avantgarde, die – online und offline vernetzt – auf der Suche ist nach neuen Grenzen und neuen Lösungen. </p>
            <p class="highlight" @mouseenter="highlightStart" @mouseleave="highlightEnd">(8% der deutschen Bevölkerung)</p>
        </div>        

        <div class="textblock" >
            <h2 class="h2-orange"> Hedonisten.</h2>
            <p> Die spaß- und erlebnisorientierte mo- derne Unterschicht, die häufig angepasst im Beruf ist, aber in der Freizeit aus den Zwängen des Alltags ausbrechen will. </p> 
            <p class="highlight" @mouseenter="highlightStart" @mouseleave="highlightEnd">(15% der deutschen Bevölkerung)</p>
        </div>        

    </div>    
</template>

<script>
import gsap from "gsap"
export default {
    name: 'Zielgruppe',
    props: {},
    methods: {
        highlightStart(){
            var tl = gsap.timeline();
            tl.to(".highlight:hover",{duration: .2, color: "#D94A4A", opacity: 1,});
        },
        highlightEnd(){
            var tl = gsap.timeline();
            tl.to(".highlight",{duration: .2, color: "#cccccc",opacity: .4});
        }
    }
}
</script>

<style>
#zielgruppe {
    /* background-color: black; 
    
    width: inherit;
    height: inherit;
    align-content: center;
    padding-top: 2rem;
    margin-bottom: 2rem;
    */
}

</style>
