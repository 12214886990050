<template>
    <div id="recherche" class="component-content">

        <div class="textblock">
            <p>
            Um einen tieferes Verständnis für die Chancen und Risiken des Musizierens online zu erlangen schien es mir sinnvoll mich zunächst ganz grundsätzlich mit dem Musizieren in Gruppen 
            auseinander zu setzen.
            </p> 
            <h2> Sinnliches Hören</h2>   
            <p>
                Möchte man sich mit der Frage beschäftigen wie 
                Musik erstellt wird, muss man sich zwangsläufig auch die Frage stellen, auf 
                welche Art und Weise man
                „hören“ muss. 
                In der folgenden Studie haben sich die Forschenden mit Musikern beschäftigt, die im Streichorchester 
                zusammen musizieren.
                Hierbei sind die Begriffe der Position, Perspektive und Reziprozität, welche im folgenden Text noch definiert werden, im Vordergrund (vgl. Eisewicht & Hitzler & Schäfer, 2021, S.90ff).

            </p>    
            <h3> Forschungsdesign</h3>   
            <p>
                Für die Studie wurde ein Streichensemble von fünf Personen in verschiedenen Sitzpositionen gewählt. 
                Diese erhielten die 
                Aufgabe zu musizieren und im Anschluss ihre Eindrücke wiederzugeben.
                Die akustische Perspektive entsteht dabei unter anderem durch die Körperform des Musizierenden und seines individuell wahrgenommenen Frequenzspektrums. Außerdem beeinflusst die eigene Positionierung des Körpers unabhängig von der Sitzposition die Perspektive (vgl. Eisewicht & Hitzler & Schäfer, 2021, S.104).
                Die Studie wurde auf Video aufgenommen.
            </p>    
            <h3> Das Hörkoordinationsproblem</h3>   
            <p>
                Um die Abhängigkeiten des Hörens von den Faktoren Position und Perspektive zu zeigen, wurden im Versuch vier verschiedene Sitzpositionen eingenommen. 
                (vgl. Eisewicht & Hitzler & Schäfer, 2021, S.91ff)
                Schnell entwickelt sich eine Problematik, bei der ein einzelnes Ensemblemitglied nicht richtig zu hören war. Hiermit ist nicht gemeint, dass die Testpersonen gar nichts hörten, sondern dass lediglich eine vielschitigere Art des Hörens nötig war. So existieren in den Kompositionen Stellen, mit einer sogenannten „erhöhten Kooperationsnotwendigkeit“ bei denen zwei Instrumentalisten gleichzeitig 
                beginnen zu spielen, gleichschnell spielen und zur gleichen Zeit aufhören zu spielen. Außerdem muss zusätzlich, besonders auf die Intonation geachtet werden (vgl. Eisewicht & Hitzler & Schäfer, 2021, S.97).
                Auch die anderen Musizierenden erwarten vermutlich 
                gewisse Stimmen und Tonhöhen in einer bestimmten Stärke wahrzunehmen. Diese Problematik wird im folgenden „Hörkoordinationsproblem“ genannt.
                Die Beschaffenheit des Raumes und all was in ihm ist beeinflussen hierbei genauso das Hörerlebnis wie die Körperformation, also wie die Musizierenden sitzen (vgl. Eisewicht & Hitzler & Schäfer, 2021, S.101). 
            </p>   

            <h2> Sinnlickeit beim Musizieren</h2>   
            <p>
                Das gemeinsame Musizieren und hören des Anderen in seiner ganzen Fülle wird dann als Reziprozität bezeichnet und stellt eine Form der sinnlichen Wahrnehmung dar (vgl. Eisewicht & Hitzler & Schäfer, 2021, S.106).
                Musizieren ist stets eine körperliche Erfahrung bei der das präzise Hören der anderen Musizierenden wichtiger zu sein scheint als das Sehen (vgl. Eisewicht & Hitzler & Schäfer, 2021, S.104).
                Die Positionierung des Sitzplatzes der Musizierenden wie auch deren Körperformation bestimmen hierbei die individuelle Perspektive (vgl. Eisewicht & Hitzler & Schäfer, 2021, S.105).
            </p>    
            <h3 class="h3-orange"> Relevanz</h3>   
            <p>
               Diese Studie war besonders hilfreich um die Aspekte zu ermitteln die präzises Hören ermöglichen. Diese Aspekte dienten dann als Grundlage für die Entwicklung eines an die Zielgruppe angepassten Nutzererlebnisses.
            </p>  
            <h2> Kooperation und Kollaboration in der Musikerstellung </h2>
            <p>  
                Im Anschluss fiel mir auf, dass eine nähere Betrachtung der Interaktionen während des Musizierens in Gruppen wichtig wäre. 
                Dabei stieß ich auf eine Forschungsarbeit mit dem Namen “Kollaboration und Kooperation beim Klassenmusizieren mit Populärer Musik. Musikmachen in der Schule im Spannungsfeld von Lernen mit der Gruppe und für die Gruppe”. 
                Diese Forschungsarbeit bezieht sich auf eine Gruppe von Schülern der 9. Klassen. Sie erschien mir interessant da verschiedene Formen der Zusammenarbeit in Kooperation und Kollaboration erforscht wurden (vgl. Godau, 2018, S.132). 
                Diese Studie hatte sich, in Anlehnung an das „Musical-Futures-Projekt“ (vgl. Green, 2008) mit der Frage beschäftigt, wie sich 
                Gruppen von Schülern beim musizieren verhalten. Dabei wurde betrachtet, wie sie sich verhalten, wenn Lehrer dabei sind. Im Fokus lag jedoch, wie die Schüler sich beim Musizieren selbst organisieren, wenn man sie nur lies (vgl. Godau, 2018, S.133). 
                Die in der Studie produzierten Performances und anschließenden Interviews wurden mit einem Diktiergerät aufgenommen.
                Hierbei wird ein iterativ-zyklischer Forschungsprozess, mit einem Vergleich von neuen und alten Daten durchlaufen. Es wird das Paradigma des operativen Konstruktivismus angewandt.
                In der Studie von Marc Godau wird eine Gruppe als Soziales System mit Kommunikation als Hauptaktion benannt.
                In diesem Artikel wird hierbei Bezug auf die Spezifik von Kollaboration und Kooperation genommen.

                Der Begriff der Kollaboration, wurde als interaktionales handeln im Kollektiv definiert. Kooperation hingegen ist hier das handlungsdifferente aufgeteilte Arbeiten. Im Folgenden 
                sollen Prozesse innerhalb der Gruppe, diesen zwei 
                Kategorien zugeordnet werden (vgl. Godau, 2018, S.135).

            </p>   

            <h3> Kollaborieren</h3>   
            <p>
                Hier steht der individuelle Wissensaufbau und die 
                Verteilung von Wissen in der Gruppe  im Vordergrund. Der Vorteil des kooperativen Lernens, ist die Möglichkeit Fehler einzelner zu lösen ohne die Gruppe damit aufzuhalten.
                Kooperation ist stets ein Ergebnis von expliziten oder impliziten Entscheidungen. Eine explizite Entscheidung wird stets kommuniziert und kann hierbei die Wahl des einzuübenden Songs sein, da einige Schüler diesen bereits beherrschen.
                Implizite Entscheidungen werden nicht kommuniziert und können beispielsweise Vorwissen zu einem Instrument oder Song sein (vgl. Godau, 2018, S.137-138).
                Hierbei zeigte sich, dass das selbstständige Einproben und das spätere Zusammenfügen im Unterricht von einer Mädchengruppe bereits als probate Methode angewandt wurde.

            </p> 
            <h3> Kooperation und Kollaboration in der Gruppe</h3>   
            <p>
                Die Gruppe der selbständig lernenden Schüler und die der von Musiklehrern angeleitete Gruppe wurden jeweils in Untergruppen nach Instrument eingeteilt.
                Diese Untergruppen arbeiteten dann kollaborativ zusammen (vgl. Godau, 2018, S.139).   
                Hierbei wurde festgestellt, dass gerade das 
                kooperative Verteilen und 
                Übernehmen von diversen 
                Aufgaben, in selbstständigen, Gruppen als 
                anstrengend wahrgenommen wurde. Einige Schüler kollaborieren hierbei in der Gruppe, wohingegen alle Schüler kooperieren in der Gruppe.
            </p> 
            <h3> Diskussion </h3>
            <p>
                Keine der beiden Arten des Musizierens kann allgemein als überlegen angesehen werden (vgl. Godau, 2018, S.140). Es ist vielmehr so, dass sich die Kollaboration eher mit den Mitgliedern und ihrer Innenwelt beschäftigt. Die Kooperation ist hingegen nach Außen gerichtet und reflektiert die Erwartungshaltung der Lehrenden, des Publikums und der fokussierten Aufgabe.
                Insgesamt wurde gemeinsames, also kollaboratives lernen als spaßiger gewertet. Dieses kann jedoch ablenken und birgt damit ein Risiko. Jedoch sollten auch die Vorteile Der Kollaboration betrachtet werden. 
                Diese können sich zum Beispiel in einer Förderung des 
                kreativen Potentials äußern.
                Während der Studie sind auf diese Weise Tänze entstanden. Auch Improvisationen auf Instrumenten, die den Musikern vorher nicht bekannt waren wurden entwickelt.
                Auch das Kooperieren bietet Chancen und Risiken. Ein ausgeweiteter Zeitraum durch das Einüben von Instrumenten zuhause ist nur ein Beispiel. So kann das Spiel eines Schülers als Wissen verwendet werden, anstatt es als Lerngegenstand zu betrachten. 
                Nachteile können jedoch die erwähnten Schwierigkeiten bei der Verteilung und Übernahme von Aufgaben sein, wodurch ein linearer Ablauf entsteht. Dieser Ablauf beginnt meist mit Kooperation , worauf die Kollaboration folgt.
                (vgl. Godau, 2018, S.141).
            </p> 
            <h3> Relevanz</h3>
            <p>
                Diese Forschungsarbeit zeigte mir die Bedeutung von kollaborativen und kooperativen Phasen während des Musizierens und auch deren Stärken und 
                Schwächen. 
                Diese Erkenntnisse halfen mir ein Konzept mit kollaborativen und kooperativen Phasen zu entwickeln, 
                welche deren jeweilige Stärken nutzen.
            </p>

            <h2> Digitalisierung der Berliner Philharmonie</h2>
            <p>
                Besonders Interessant für die Gestaltung eines Unternehmens in der Musik-
                branche war für mich der digitale Transformationsprozess der Berliner Philharmonie.
                So existieren einige parallelen zu „timbre“, wie die Bemühung um eine Distribution der Live-Performances jenseits eines physischen Raumes.
            </p> 

            <h3> Limitation des Platzes im Konzertsaal als Motivation für die Digitalisierung</h3>   
            <p>
                Die Motivation der Berliner Philharmonie zur digitalen Transformation entstand durch einen Rückgang im Verkauf von physischen Medien wie CDs und DVDs. Somit stieg die Bedeutung des Konzertbetriebes. 
                Jedoch ist der Platz in 
                Konzertsälen begrenzt wodurch, das Wachstum der Zielgruppen auf natürliche Grenzen stieß (vgl. Oswald & Krcmar, 2018, S.126).
                Dies führte zur Entscheidung der Berliner Philharmonie ihren klassischen Konzertbetrieb online 
                verfügbar zu machen. 
                Außerdem wurde entschieden sich unabhängig von bestehenden Plattformen um die Umsetzung einer eigenen Lösung zu 
                bemühen. (vgl. Oswald & Krcmar, 2018, S.128) 
                Um den im Prozess der Transformation benötigten Handlungsspielraum zu gewährleisten, hat die Berliner Philharmonie eine Tochtergesellschaft als GmbH gegründet. (vgl. Oswald & Krcmar, 2018, S.130)
                Kurze Entscheidungswege und die beschränkte Haftung waren hierbei ausschlaggebende Vorteile. (vgl. Oswald & Krcmar, 2018, S.131)
                Für die technische Umsetzung eines Content-
                Delivery-Netzwerkes arbeitete die Berliner Philharmonie mit einem Advanced Consulting Partner, der Amazon Services zusammen. (vgl. Oswald & Krcmar, 2018, S.133) Später wurde jedoch auf eine andere 
                Organisationsstruktur 
                gesetzt. So wurde das IT-Projektmanegement für das Projekt von der Berliner Philharmonie übernommen. Desweiteren wurde dezentral entwickelt, eine 
                transparente Projektkoordination eingerichtet und auf eine Multi-Sourcing-
                Strategie gesetzt. 

                Bei der Kommunikation mit der Zielgruppe setzt die Berliner Philharmonie auf eine nonverbale Kommunikation mittels der Konzertaufnahmen die für sich selbst sprechen. (vgl. Oswald & Krcmar, 2018, S.137) Hierbei ist die Berliner Philharmonie die in der klassischen Musik am stärksten vertretene Institution. Sie kann 800.000 Abonnenten und 4 Millionen Impressionen pro Monat vorweisen (vgl. Oswald & Krcmar, 2018, S.139).
                Der Grundsatz der Berliner Philharmonie war bei diesem Projekt, ein ähnlich hochwertiges Produkt wie eine CD zu entwickeln. 
            </p> 
            <h2> Lesson Learned</h2>
            <p> 
                Die Berliner Philharmonie kam zu dem Schluss, dass eine Erweiterung des Marktes durch Live-Übertragungen, sehr begeistert aufgenommen wurde (vgl. Oswald & Krcmar, 2018, S.142).
                Es ist dabei wichtig eine klare Vision zu entwickeln um die nötigen Veränderung umsetzen zu können (vgl. Oswald & Krcmar, 2018, S.143).
                Des weiteren hat sich die Rechtsform GmbH für die Berliner Philharmonie als das Modell mit der passenden Flexibilität herausgestellt.
                Gerade bei der Anforderung von externem Know-How ist es wichtig darauf zu achten, dass keine zu starken Abhängigkeiten entstehen (vgl. Oswald & Krcmar, 2018, S.144).
                Auf technischer Sicht wird empfohlen gemeinsame Plattformen zur Ideen- und Entscheidungsfindung
                einzurichten. Auch die Anwendung von “Pair-Programming” und “Code-Reviews” wurden in diesem
            </p>
            <h3> Relevanz</h3>
            <p>
                Die Forschungsarbeit zur digitale Transformation der Berliner Philharmonie war besonders relevant, da sie einen Einblick in ein Unternehmen gibt, welches ein ähnliches Konzept erfolgreich umgesetzt hat. Außerdem ist zu erwähnen, dass die Berliner Philharmonie, in ihrem Bereich Marktführer ist und sich somit auch als unternehmerisches Vorbild qualifiziert.
            </p>
            <h2> Die Förderung von Inklusion durch gemeinsames Musizieren</h2>
            <p> 
                Diese Forschungsarbeit beschäftigt sich mit der Fragestellung wie musizieren in einer Klasse, Inklusion fördern kann.
            </p>            
            <h3> Definition von Inklusion</h3>
            <p> 
                Zunächst ist es wichtig den Begriff der Inklusion hinreichend zu definieren. 
                Im folgenden Text wird 
                diese als ein Menschenrecht zur Überwindung sozialer Ungleichheit, Aussonderung und Marginalisierung definiert (vgl. Ziemen, 2012).
                Das Gegenteil von Inklusion ist hierbei nicht die absolute Exklusion, sondern auch schon ein Teilausschluss oder eine fehlende Wertschätzung einer Person. (vgl. Herzog, 2018, S.78)
                Inklusive Prozesse fördern somit die wertschätzende und anerkennende Wahrnehmung individueller Vielfalt und Differenz.
                Um Inklusion zu schaffen ist es wichtig Barrieren jeglicher Art abzubauen (vgl. Herzog, 2018, S.79).
                Dieser Abbau kann durch eine Normalisierung von differenten Handlungen geschehen.                
            </p>            
            <h2> Bedeutungskonstruktionen</h2>
            <p> 
                Die Bedeutung, ist hier der Sinn, den eine Sache, eine Situation, eine Erscheinung oder Handlung für ein 
                Individum hat. Somit ist eine Bedeutungskonstruktion oder auch Bedeutungskonstrukt, ein interpretativer Prozess, der konstant eine Bedeutung schafft.
                Dieser Sinn wird hierbei vom Subjekt im Kontext der Interaktion, mit seiner Umwelt geschaffen. Es existieren zwei Dimmensionen des Sinnes (vgl. Herzog, 2018, S.80).
                Zunächst wird rein 
                subjektiv über die 
                jeweilige Bedeutung 
                geurteilt. Jedoch wird intersubjektiv auch überprüft ob eigene Bedeutungskonstrukte, mit denen der Umwelt zusammenpassen.
                Ob ein Bedeutungskonstrukt brauchbar ist, wird durch gemeinsame Absprache entschieden.
                Diese Absprache entsteht durch eine soziale Interaktion, die im folgenden als Aushandlungsprozess benannt wird. Durch den Aushandlungsprozess können gemeinsame 
                Bedeutungskonstruktionen geschaffen werden (vgl. Herzog, 2018, S.81).
                Dies geschieht über sogenannte Symbole, 
                welche Handlungen darstellen, die beim Gegenüber die gleiche Bedeutung haben, da sie ganz bestimmte Reaktionen auslösen (Mead,1934/2010,S.85). Interaktionen auf Basis von Symbolen werden im folgenden Text als Symbolischer Interaktionismus bezeichnet (vgl. Herzog, 2018, S.84).  
            </p>
            <h2> Schaffung von Inklussion durch gemeinsame Bedeutungskonstruktionen</h2>
            <p> 
                Es ist festzustellen, dass Interaktionen mit marginalisierten Gruppen seltener oder ganz ausfallen, wenn keine Kontakte im Freundeskreis oder der Familie vorhanden sind (S.81-82).
                Durch seltene oder ausfallende Interaktionen ist es kaum bis gar nicht möglich gemeinsame Bedeutungskonstrukte zu schaffen.
                Dies betont die Wichtigkeit der Schaffung von Möglichkeiten zur gemeinsamen Interaktion.
            </p>
            <h2> Bedeutungsonstruktionen in der Musik</h2>
            <p> 
                Das gemeinsame Musizieren zeigt sich als ausgezeichnetes Feld zur Entwicklung von Bedeutungskonstruktionen (vgl. Herzog, 2018, S.84).
                Dies ist zum Beispiel schon am Gespräch über Musik zu erkennen, da hier durch die bestehende Vielfalt, Differenz, etc. schon ein Aushandlungsprozess 
                angestoßen wird. 
                Mindestens genauso 
                wichtig ist jedoch der Klang selbst, mit welchem eigene Bedeutungsonstruktionen geschaffen werden können (vgl. Herzog, 2018, S.85). 
                Das gemeinsames Musizieren, welches in dieser Arbeit beschrieben wurde hatte zunächst nicht das Ziel der Inklusion. Die Inklusion entstand somit automatisch. Dies liegt darin begründet das gemeinsames 
                Musizieren für sich 
                genommen schon ein Aushandlungsprozess ist, da die Musizierenden das Verhalten der anderen antizipieren 
                müssen. 
            </p>
            <h2> Potential für inklusive Prozesse durch gemeinsames Musizieren</h2>
            <p> 
                Schlussendlich kann man sagen, dass musizieren in Gruppe inklusive Prozesse ermöglicht. Besonders interessant sind hierbei Ansätze die den Symbolische Interaktionismus mitbedenken, da somit Sprache als mögliches Hinderniss umgangen werden kann (vgl. Herzog, 2018, S.87).
                Man muss jedoch auch sagen, dass nicht jeder Aushandlungsprozess automatisch zu einer 
                anerkennenden und 
                wertschätzenden Haltung gegenüber differentem 
                Handeln führt.
            </p>
            <h2> Relevanz</h2>
            <p> 
                Im Rahmen der Entwicklung des Manifestos für „timbre“ wurde Inklusion als eine der persönlichen Maxime definiert. Die Tatsache, dass ein Zusammenhang zwischen Musizieren in Gruppen und der Förderung von Inklusion besteht, kann die Ziele des Manifestos auf besonders natürliche Weise unterstützen.
            </p>
            <h2> </h2>
            <p> 
                    
            </p>                        
        </div>  

    </div>    
</template>

<script>
export default {
    name: 'Recherche',
    props: {}
}
</script>

<style>
#recherche {
    flex-direction: row!important;
    width: inherit;
    height: inherit;
    align-content: center;
    margin-bottom: 2rem;
    text-align: center;   
}

</style>
