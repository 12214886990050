<template>
    <div >
         <video id="trailer" controls>
            <source src="../assets/videos/Bachelor-Trailer-comp.mp4" type="video/mp4">
            <source src="movie.ogg" type="video/ogg">
            Your browser does not support the video tag.
        </video> 
    </div>    
</template>

<script>
export default {
    name: 'Trailer',
    props: {}
}
</script>

<style>
#trailer {
    justify-content: center;
    /* background-color: black; */
    width: 40%;
    height: auto;
    /* height: inherit; */
    align-content: center;
    padding-top: 2rem;
    padding-bottom: 2rem;

}

</style>
