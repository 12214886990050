<template>
    <div id="ausblick" class="component-content">

        <div class="textblock">
            <h2 class="h2-turkis"> Anzeige von Noten der Bandmitglieder </h2>
            <p>
                Für eine bessere Übersicht, welche Tonart derzeit in der Performance angewandt wird, soll eine Noten-anzei- ge hinzugefügt werden.
                Diese Notenanzeige ist ein Modul, dass die Tonhöhe und eventuelle Akkorde anhand der Obertonhäufun- gen der Frequenz ermittelt. Diese Information wird dann im Rahmen des Avatars des Bandmitgliedes angezeigt.
            </p>
        </div>  
        <div class="textblock">
            <h2 class="h2-turkis"> MIDI-Modus </h2>
            <p>
                Um eine noch geringer Latenz zu gewährleisten, ist geplant einen sogenannten „MIDI-Modus“ einzuführen.
                In diesem Modus werden lokal auf dem timbre Device diverse Klangerzeuger angeboten, die über das MIDI-Protokoll angesteuert werden können. So werden statt der Audiospuren von Bandmitgliedern lediglich deren MIDI-Daten emp- fangen. Diese Daten lösen ihrerseits die entsprechen- den Noten auf dem lokalen Instrument aus.
                Möchte man selbst eine Sequenz einspielen kann man hierzu jedes Gerät wie z.B. ein Masterkeyboard, das MIDI über 5Pin-Kabel oder USB ausgibt verwenden.
            </p>
        </div>  
        <div class="textblock">
            <h2 class="h2-turkis"> Integration in soziale Netzwerke </h2>
            <p>
                Ein wichtiger und erstrebenswerter Schritt für die Zukunft wäre die Integration von timbre in das Ökosystem der Sozialen Netzwerke als eigenes Format.
                So könnten timbre Performances ähnlich der Instagram Livestreams nativ in sozialen Netzwerken in eigenem Format veröffentlicht werden.
                Dies würde die Popularität timbre imens steigern wie auch die der vertretenen Künstler welche ein einheitliches Medium zur Distribution ihrer Kunst haben.
            </p>
        </div>  
        <div class="textblock">
            <h2 class="h2-turkis"> Veröffentlichung von gemeinsamen Songs </h2>
            <p>
                Ein weiterer Interessanter Ansatz wäre die direkte gewinnorientierte Distribution von auf timbre entstandenen musikalischen Kunstwerken an ein breites Publik. Sowohl ein Modell im Sinne einer digitalen Eintritskarte, die einmalig bezahlt wird, als auch ein Abomodell zum Besuch mehrer Performances wäre denkbar.
            </p>
        </div>  
        <div class="textblock">
            <h2 class="h2-turkis"> VR-/AR- Interface </h2>
            <p>
                Ein fernes Ziel ist die Umsetzung eines virtuellen Studios komplett im virtuellen Raum. Eine VR-Lösung (Virtual Reality) aber auch AR-Lösung (Argumented Reality), bei der man die komplette Band auch visuell vor sich hat wären denkbar. In Kombination mit 3D-Sound kann hiermit die immersion vermutlich beträchtlich erhöht werden. Hierzu muss man jedoch sagen, das die derzeitige Netzanbindung selbst in den Großstädten Deutschland nur vereinzelt Leistungsstark genug ist. Durch die Echzeitberechnung jeglicher Audiodaten sind hierbei Netzschwankungen fatal.
            </p>
        </div>  
        <div class="textblock">
            <h2 class="h2-turkis"> Spezielle Lehrer und Schüler Features </h2>
            <p>
                Besondere Funktionen für die Musikdidaktik sind fest geplanter Bestandteil des zukünftigen Konzeptes um die Zielgruppe zu skalieren. Wie diese Erweiterungen konkret aussehen sollen wird, durch Usability Tests mit diversen Usecases er- mittelt werden.
            </p>
        </div>  
        <div class="textblock">
            <h2 class="h2-turkis"> Fazit </h2>
            <p>
                Das Projekt hat Spaß gemacht da die Thematik mich auch privat beschäftigt und ich plane es zumindest im privaten Rahmen fortzusetzen.
            </p>
        </div>  

    </div>    
</template>

<script>
export default {
    name: 'Ausblick',
    props: {}
}
</script>

<style>
#ausblick {
    /* background-color: black; */
    width: inherit;
    height: inherit;
    align-content: center;
    padding-top: 2rem;
    margin-bottom: 2rem;
}

</style>
