<template>
    <div id="parts" class="component-content">


        <div class="textblock">

            <img @mouseenter="scaleImage" @mouseleave="scaleDownImage" @click="show = !show" class="component-image img" alt="Akku für die Stroversorgung von timbre" src="../assets/akku-vectorG.png">
            <h2> Varta-Akku (10.000mAh) </h2>   
            <p>
                Um timbre für den mobilen Gebrauch nutzbar zu machen wurde ein Akku verbaut. Dieser hat eine ausgangsstromstärke von 2,4-3 A. Aus diesen Daten lässt sich die Akkulaufzeit von 250 Minuten errechnen.
                Rechnung:
                10Ah / 2,4A x 60 = 250 Min.
            </p>         
        </div> 

        <div class="textblock">

            <img @mouseenter="scaleImage" @mouseleave="scaleDownImage" class="component-image img" alt="Einplatinencomputer, das Herzstück von timbre" src="../assets/pi.png">
            <h2> Raspberry Pi 4 Model B (2GB) </h2>    
            <p>   
                In timbre ist ein Raspberry Pi 4 B mit 2GB RAM verbaut. Die größe des Speicher ist inspiriert vom Sampler Akain MPC Live welches ähnliche Leistungsanforderungen aufweist. Auf dem Computer werden die Hauptprogramme gestartet und verbindung zum Internet aufgebaut. Der Mikrochip steuert auch den Touchscreen.  
            </p> 

        </div>

        <div class="textblock">

            <img @mouseenter="scaleImage" @mouseleave="scaleDownImage" class="component-image img" alt="Das Audiointerface, zuständig für die Digitl-/Analog-Wandlung der verwendeten Audiosignale." src="../assets/audiointerface.png">
            <h2> Native Instruments Komplete Audio 1</h2>   
            <p>
                Das Audiointerface Komplete Audio 1 von Native Instruments hat ein DA/AD-Wandler verbaut, mit einer 192Khz Samplerate und einer 24 Bit Auflösung. Dies entspricht aktuellen Studiostandards.
                Das Audiointerface ist hierbei die Komponente die Wandlung von analoge in digitale Signale und vice versa durchführt.
            </p>    
        </div>    

        <div class="textblock">

            <img @mouseenter="scaleImage" @mouseleave="scaleDownImage" class="component-image img" alt="Dient der Ausgabe der Audiosignale." src="../assets/box.png">
            <h2> Lautsprecher (Yamaha HS7)</h2>   
            <p>    
                Die Lautsprecher sind eigendlich kein Teil von timbre, werden jedoch für den Betrieb benötigt. Die verwendeten Lautspecher sind  in kleinen bis mittleren Projektstudios sehr beliebt und dienen als klangliche Referenz.
            </p> 
        </div>

        <div class="textblock">
            <img @mouseenter="scaleImage" @mouseleave="scaleDownImage" class="component-image img" alt="Für die Einstellung von Volumen und Panning." src="../assets/touch.png">
            <h2> Touchscreen </h2>   
            <p>
                Der Touchscreen fungiert als Hauptinteraktionsfläche für die Einstellung von timbre.
            </p> 
        </div>   

        <div class="textblock">
            <img @mouseenter="scaleImage" @mouseleave="scaleDownImage" class="component-image img" alt="Veränderung der Instrumenten-Eingangs-Lautstärke." src="../assets/encoder.png">
            <h2> Encoder</h2>   
            <p>
                Der Endlosencoder dient für die Steuerung des Eingangssignales eines des angeschlossenen 
                Instrumentes oder Mikrofon. 
            </p> 
        </div>   

        <div class="textblock">

            <img @mouseenter="scaleImage" @mouseleave="scaleDownImage" class="component-image img" alt="Zuständig für die Bedienung während einer Session." src="../assets/switch.png">
            <h2> Druckschalter </h2>      
            <p>
                Druckschalter werden für Entscheidungen während einer Session verwendet.
                Es existieren Druckschalter für:
                <ul>
                    <li> 
                       - akzeptieren 
                    </li>
                    <li> 
                       - ablehnen 
                    </li>
                    <li> 
                       - Menu 
                    </li>
                    <li> 
                       - Funktion 
                    </li>
                    <li> 
                       - Recording 
                    </li>
                </ul>
            </p> 
        </div>  

    </div>    
</template>

<script>
import gsap from "gsap"
export default {
    name: 'Komponenten',
    props: {},
    data(){
        return {
            show: false
        }
    }, 
    methods: {
        scaleImage() {
            var tl = gsap.timeline();
            tl.to(".component-image:hover",{duration: .5, scale: 1.2});
            var tl2 = gsap.timeline();
            tl2.to(".content",{duration: .5, backgroundColor: "#464646"});
        },
        scaleDownImage() {
            var tl = gsap.timeline();
            tl.to(".component-image",{duration: .5, scale: 1});
            var tl2 = gsap.timeline();
            tl2.to(".content",{duration: .5, backgroundColor: "#1a1a1a"});              
        }
    }
}
</script>

<style>

</style>
