<template>
    <div id="marketing" class="component-content">
        <div class="textblock">
            <h2> Grundstrategie</h2>
            <p>
                Die Grundstrategie des Marketingkonzeptes von „timbre“ besteht in einer Abdeckung der durch die TAM ermittelten Märkte durch diverse Marketing-Kanäle. Da die expeditive Zielgruppe vor allem als Triebfeder der Digitalisierung gesehen werden und die derzeitige Elite der Performer laut [Praxis der Sinus-Milieus][S.37] ablösen wird, sind die wichtigsten Marketingkanäle für timbre digital. Zurzeit basiert die digitale Marketingstrategie stark auf Instagram, soll zukünftig jedoch auf jeweils etablierte Soziale Medien erweitert werden.
            </p>        

            <h2> Infleuncer Marketing</h2>

            <h3 class="h4-t"> Funktion</h3>        
                <p>
                    Das Influencer Marketing ist der wohl wichtigste Baustein in der Marketingstrategie von timbre. Das Influencermarketing hat für den Einstieg in den ersten “Target Market” keine großen Bedeutung. Jedoch ist diese Art des Marketings für den Eintritt in internationale Märkte und somit auch die Erschließung des “Total Addressable Markets” durchaus relevant. Dabei überzeugt die immense Anzahl an Abonnenten der 
                    Influencer und ihrer starke Fanbindung.

                    Um die Influencer in den verschiedenen Phasen der Unternehmensentwicklung geplant anfragen und einsetzen zu können wurden diese in drei Kategorien eingeteilt. Dabei
                    sollen die Big Player hauptsächlich den “Total Addressable Market”(über 1 Mio Abonnenten), Medium Player den “Served Available Market” (über 100 Tsd Abonnenten) und Small Player (über 10 Tsd Abonnenten) “Target Market” zum Kauf motivieren. 
                </p>        
            
            <h3 class="h4-t"> Mögliche Keypartner</h3>        
                <p>
                    Andew Huang: 
                    Funktion: International (Big Player)
                    Youtube: 2,24 Mio Abos
                    Preis: ca. 24.640€
                    Adam Neely: 
                    Funktion: International (Big Player)
                    Youtube: 1,5 Mio Abos
                    Preis: ca. 16.500€
                    Look Mum No Computer:
                    Funktion: International und UK national (Medium Player)
                    Youtube: 499.001 Abos
                    Preis: ca. 5.489,011€
                    Red Means Recording:
                    Funktion: International (Medium Player)
                    Youtube: 397.000 Abos
                    Preis: ca. 4.367€
                    True Cuckoo: 
                    Funktion: International (Medium Player) 
                    Youtube: 144.000 Abos
                    Preis: ca. 1.584€
                    Sol State:
                    Funktion: International (Medium Player)
                    Youtube: 125.000 Abos
                    Preis: ca. 1.375€
                    Paul Marx Media:
                    Funktion: National in Deutschland (Small Player)
                    Youtube: 41.400 Abos 
                    Preis: ca. 405,72€

                    Stand: 19.02.2022
                </p>                        
        </div>
        <div class="textblock">
            <h2> Instagram Anzeige</h2>

                <h3 class="h4-t"> Funktion</h3>        
                    <p>
                        Auch die Instagram Werbung in Form von automatisierten Werbeanzeigen in der App und die direkte Möglichkeit über das Pop-Up-Fenster timbre zu erwerben sollen stetig Neukunden gewonnen werden. Diese Variante des Performance Marketings ist vergleichsweise günstig und eignet sich daher auch für den mittel- und langfristigen Einsatz.
                        Dieser Marketing-Channel unterstützt somit hauptsächlich den “Target Market”, “Served Available Market” und “Total Addressable Market”.
                    </p>    

                <h3 class="h4-t"> Kosten</h3>        
                    <p>
                        Beauftragt man eine Agentur sind mit 
                        einmaligen Kosten von ungefähr 3600€ zu rechnen und anschließend mit 50-500€ im Monat.
                        Ohne Agentur ist nur mit einem monatlichen Betrag von 50-500€ im Monat zu 
                        rechenen.
                    </p>  
        </div>
        <div class="textblock"> 
            <h2> Magazine</h2>

                <h3 class="h4-t"> Funktion </h3>        
                    <p>
                        Um die Bekanntheit gerade im professionellen Musikbereich zu erhöhen wird unterstützend auf gezielte Werbung in Fachmagazinen gesetzt. Auch klassische weniger technologisierte Ensembles und Band sollen mit dieser Werbeform angesprochen werden. Da bei diesen Kunden davon ausgegangen wird, dass diese im professionellen oder im ambitionierten Amateurbereich angesiedelt sind, sollen hiermit neue langfristige Bestandskunden generiert werden. Somit können die Fachmagazine die Präsenz von timbre im “Total Addressable Market” erhöhen.                
                    </p>        
                <h3 class="h4-t"> Mögliche Keypartner </h3>        
                    <ul>
                        <li> - Rolling Stone </li>  
                        <li> - Laut.de </li> 
                        <li> - Musikexpress </li> 
                    </ul> 
            
                <h3 class="h4-t"> Kosten </h3>     
                    <p>
                        ca. 50.000-90.000€ 
                    </p>    
        </div>

        <div class="textblock"> 
            <h2> Musikmesse</h2>

                <h3 class="h4-t"> Funktion </h3>     
                    <p>
                        Gerade für eine mittelfristige Platzierung von timbre als beständige Marke, welche einen festen Platz in der Musikinstrumentenbranche einnimmt, ist eine Anwesenheit auf den gängigen Musikmessen förderlich.
                        Zum einen bleiben Neuigkeiten rund um timbre von der Presse beobachtet, zum anderen kann der Austausch mit möglichen Partnern und Konkurrenten in der Branche die eigene Produktentwicklung fördern.
                        Dieser Marketing-Kanal dient vor allem der Erweiterung des “Served Available Market”. 
                    </p>         
                <h3 class="h4-t"> Mögliche Keypartner </h3>     
                    <ul>
                        <li> - Namm </li>  
                        <li> - Musikmesse Frankfurt </li> 
                        <li> - Neue ArT </li> 
                        <li> - MIXCON </li>                     
                    </ul> 
                    <h3 class="h4-t"> Kosten </h3>     
                    <ul>
                        <li> - Ausstellungen mit interaktiven und Hands-On-Elementen: 1.400 – 2.000 €</li>  
                        <li> - Multimediale Ausstellungen: 1.700 – 2.900 € </li> 
                        <li> - <b> Musikmesse Frankfurt:</b>
                                110-175€ pro m² (bei 20m² 2200-3500€)
                                je Nachdem ob Freigelände mehrseitig offen oder zweistöckig.- Multimediale Ausstellungen: 1.700 – 2.900 € 
                        </li>  
                    </ul> 
        </div>
    </div>    
</template>

<script>
export default {
    name: 'Marketing',
    props: {}
}
</script>

<style>
#marketing {
    /* background-color: black; */
    width: inherit;
    height: inherit;
    align-content: center;
    padding-top: 2rem;
    margin-bottom: 2rem;
}

</style>
