<template>
    <div id="idee" class="demos">
        <div>

            <img @click="connectDevice1" class="device1 img" src="../assets/timbre-simple-free.png"/>
            <div class="line"> </div>
            <img @click="connectDevice2" class="device2 img" src="../assets/timbre-simple-free.png"/>
            
        </div>
        <div class="align-butn">
            
            <!-- Buttons zum bedienen der Audioausgabe -->
            <button @click="playAudio">Play Audio</button>
            <button @click="reset">Reset</button>
            <div id="pos" class="info" @click="show = !show"> i </div>
            <audio id="audio">
                <source src="../assets/audio/AllButNoVocal.wav" type="audio/wav">
            </audio>   

            <audio id="audioV">
                <source src="../assets/audio/All.wav" type="audio/wav">
            </audio> 
        </div>
            <p v-if="show == true" id="pos2" class="textblock">
            <b> timbre </b> ist ein IoT-Device zum gemeinsamen Musizieren online. 
            Probieren Sie das doch gerne einmal aus indem Sie die zwei Geräte anklicken und so miteinander verbinden.
            Mit dem Play Button können Sie hören wie sich die Audiospur ändert wenn ein zusätzliches Gerät zu einer Jamsession hinzugefügt wird. Sie können die Auswahl auch zurücksetzen um die Audioausgabe von nur einem Gerät zu hören. 
            </p>
    </div>    
</template>

<script>
import gsap from "gsap"
export default {
    name: 'Idee',
    props: {
        
    },
    data(){
        return {
            vocalOn: false,
            audio : null,
            audioV : null,
            timbre1: false,
            timbre2: false,
            show: false,
            linieTl: undefined

        }
    },
    methods: {

        playAudio() {
            console.log("status(vocaln/timre1/timbre2): " + this.vocalOn + this.timbre1 + this.timbre2)
            // weist das audio element mit vocal sample audioV und das ohne audio zu
            this.audio = document.getElementById("audio");
            this.audioV = document.getElementById("audioV");

            // Setzt die Lautstärke auf .3
            this.audio.volume =  .3;
            this.audioV.volume =  .3;

            // Checkt ob Geräte verbunden sind um das korrespondierende Audio zu starten.
            if(this.vocalOn == true) {
                console.log("vocalOn: " + this.vocalOn);
                this.audioV.play();
            }
            else if(this.vocalOn == false){
                console.log("vocalOn: " + this.vocalOn);
                this.audio.play();
            }
            else {
                console.log("vocalOn: " + this.vocalOn);
                this.audio.play();
            }
        },

        pauseAudio() {
        
            if(this.vocalOn == true) {
                this.audioV.pause();
                console.log("vocalOn: " + this.vocalOn);
            }
            else if(this.vocalOn == false){
                this.audio.pause();
                console.log("vocalOn: " + this.vocalOn);                
            }            
            else {
                this.audio.pause();
                console.log("vocalOn: " + this.vocalOn);                
            }
        },

        connectDevice1() {
            var tl = gsap.timeline();
            var tl2 = gsap.timeline();

            tl.to(".device1",{duration: .3, scale: 1.2});
            
            this.timbre1 = true;

            if(this.timbre2 == true ) {
                tl2.to(".line",{duration: .3, height: "80px"}); 
                this.vocalOn = true;
                this.timbre1 = false;
                this.timbre2 = false;
                this.audio.pause();                
            }
            else {
                this.timbre2 = false;
            }
        },

        connectDevice2() {
            var tl = gsap.timeline();
            var tl2 = gsap.timeline();

            tl.to(".device2",{duration: .3, scale: 1.2}); 

            this.timbre2 = true;

            if(this.timbre1 == true) {  
                tl2.to(".line",{duration: .3, height: "80px"}); 
                this.vocalOn = true;
                this.timbre1 = false;
                this.timbre2 = false;
                this.audio.pause();
            }
            else {
                this.timbre1 = false;
            }
        },
        
        reset() {
            this.audioV.pause();
            this.audio.pause();            
            this.timbre1 = false;
            this.timbre2 = false;
            this.vocalOn = false;
            var tl = gsap.timeline();
            tl.to(".device1",{duration: .3, scale: 1});
            var tl3 = gsap.timeline();
            tl3.to(".device2",{duration: .3, scale: 1});
            var tl2 = gsap.timeline();
            tl2.to(".line",{duration: .3, height: "0px"}); 

        }
    }
}
</script>

<style>
#idee {
    /* background-color: black; */
    width: inherit;
    height: inherit;
    align-content: center;
    padding-top: 2rem;
    margin-bottom: 2rem;
}
.demos{

    display: flex;
    flex-direction: column;
    justify-content: space-around;

}
.devices {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.device:active {
    background-color: rgba(240, 248, 255, 0.603);
    border-radius: 20px;
}
.line {
    width: 5px;
    height: 0px;
    margin-left: 50%;
    margin-right: 50%;
    background-color: #04bfad;

}
#pos {
    margin-left: .5rem;
}
#pos2 {
    padding-top: 1rem;
}
.align-butn {
    display: flex;
    flex-direction: row!important;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
}
</style>
